import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Pricing() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="pricing-area page-section scroll-to-page" id="pricing">
            <div className="custom-container">
                <div className="pricing-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-dollar-sign"></i> pricing
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Pricing</span></h1>
                    </div>

                    <div className="pricing-table-items">
                        <div className="row">
                            <div className="col-md-6 scroll-animation"  data-aos='fade-up'>
                                <div className="pricing-table">
                                    <div className="pricing-table-header">
                                        <div className="top d-flex justify-content-between align-items-start">
                                            <h4>Digital Marketing</h4>
                                            <p className="text-right">Have any trouble with digital marketing?</p>
                                        </div>
                                        <h1>$35 <span>/ hours</span></h1>
                                    </div>
                                    <ul className="feature-lists">
                                        <li>SEO (Search Engine Optimization)</li>
                                        <li>Social Media Marketing</li>
                                        <li>Paid Advertising</li>
                                        <li>Analytics and Data Analysis</li>
                                        <li>Digital Strategy and Planning</li>
                                        <li>Remote/Online</li>
                                        <li>Work in business days, no weekend.</li>
                                        <li>Support 6 months</li>
                                    </ul>
                                    {/* <a href="" className="theme-btn">pick this package</a> */}
                                </div>
                            </div>
                            <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                                <div className="pricing-table">
                                    <div className="pricing-table-header">
                                        <div className="top d-flex justify-content-between align-items-start">
                                            <h4>Web Development</h4>
                                            <p className="text-right">Not have any design?<br/>
                                                Leave its for me</p>
                                        </div>
                                        <h1>$55 <span>/ hours</span></h1>
                                    </div>
                                    <ul className="feature-lists">
                                        <li>Don't need wireframe or anything</li>
                                        <li>Design with Figma, Framer from scratch</li>
                                        <li>Implement with React, WordPress, Laravel/PHP</li>
                                        <li>Remote/Online</li>
                                        <li>Work with both weekend</li>
                                        <li>Support 12 months</li>
                                        <li>Your project alway be priority</li>
                                        <li>Customer care gifts</li>
                                    </ul>
                                    {/* <a href="" className="theme-btn">pick this package</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    </section>
  )
}
