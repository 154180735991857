import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Digital Marketing Specialist and Full Stack Engineer</h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>A dynamic and innovative professional with dual expertise as a Fullstack Engineer and Digital Marketing Specialist, bringing together a rare combination of deep technical knowledge in software development and a creative, results-driven approach to digital marketing. With over 8 years of experience, I excel at developing cutting-edge web solutions and leveraging digital channels to enhance brand visibility, engage audiences, and drive growth. My unique skill set enables me to bridge the gap between technical development and digital marketing strategies, delivering comprehensive solutions that propel businesses forward in the digital age.</p>
            </div>
        </div>
    </section>
  )
}
